@import '../global/variables.scss';

:root{
  --custom-color: red;
}

.pad-all-0px {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.pad-all-5px {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pad-all-15px {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.pad-all-30px {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.pad-all-60px {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.pad-left-0px {
  padding-left: 0px;
}
.pad-left-5px {
  padding-left: 5px;
}
.pad-left-10px {
  padding-left: 10px;
}

.pad-left-15px {
  padding-left: 15px;
}
.pad-left-30px {
  padding-left: 30px;
}
.pad-left-45px {
  padding-left: 45px;
}
.pad-left-60px {
  padding-left: 60px;
}

.pad-right-0px {
  padding-right: 0px;
}
.pad-right-5px {
  padding-right: 5px;
}
.pad-right-15px {
  padding-right: 15px;
}
.pad-right-30px {
  padding-right: 30px;
}
.pad-right-60px {
  padding-right: 60px;
}

.pad-top-0px {
  padding-top: 0px;
}
.pad-top-5px {
  padding-top: 5px;
}
.pad-top-10px {
  padding-top: 10px;
}
.pad-top-15px {
  padding-top: 15px;
}
.pad-top-30px {
  padding-top: 30px;
}
.pad-top-60px {
  padding-top: 60px;
}

.pad-top-80px {
  padding-top: 80px;
}

.pad-top-110px {
  padding-top: 110px;
}

.pad-top-30px-desktop {
  padding-top: 30px;
}

.pad-top-128px-desktop {
  padding-top: 128.5px;
}

.pad-bottom-200px-desktop {
  padding-bottom: 200px;
}

.pad-bottom-300px-desktop {
  padding-bottom: 300px;
}

.pad-bottom-100px {
  padding-bottom: 100px;
}

.pad-bottom-60px {
  padding-bottom: 60px;
}
.pad-bottom-30px {
  padding-bottom: 30px;
}

.pad-bottom-5px {
  padding-bottom: 5px;
}

.pad-bottom-15px {
  padding-bottom: 15px;
}
.pad-bottom-0px {
  margin-bottom: 0px;
}

.background-white {
  background-color: white;
}

.background-grey {
  background-color: #f0f1f6;
}

.background-dark-blue {
  background-color: $color-blue-dark;
}

.text-primary-blue {
  color: $color-blue-primary;
}

.text-secondary-blue {
  color: $color-blue-secondary;
}

.text-tertiary-blue {
  color: $color-blue-tertiary;
}

.text-align-right {
  text-align: right;
}

.text-bold {
  font-weight: 700;
}

.text-normal-size {
  font-size: 20px;
}

.txt-primary-color {
  color: #0d2d50;
}

.txt-secondary-color {
  color: #0156d0;
}

.txt-grey {
  color: grey;
}

.txt-green {
  color: #0c7c59;
}

.txt-white {
  color: white;
}

.txt-white-inverse {
  color: #ee8a52
}

.txt-red {
  color: #e84855;
}
.txt-orange {
  color: #ff8600;
}

.txt-yellow {
  color: #f4d35e;
}

.txt-align-center {
  text-align: center;
}

.txt-align-right {
  text-align: right;
}

.txt-no-wrap {
  white-space: 'nowrap';
}

.vert-align-center {
  vertical-align: middle;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.wrap-table-text-small {
  width: 160px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.wrap-table-text-medium {
  width: 250px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.wrap-table-text-large {
  width: 500px;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.truncate-table-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
  max-width: 250px;
  transition: max-width 1s ease-in-out;
}
.truncate-table-text:hover {
  max-width: none;
  transition-delay: 1s;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-items-center {
  align-items: center;
}
.flex-justify-content-end {
  justify-content: flex-end;
}
.flex-container {
  display: flex;
}

.flex-item-align-baseline {
  align-items: baseline;
}
.flex-item-align-center {
  align-items: center;
}
.flex-align-items-end {
  align-items: flex-end;
}

.grid {
  display: grid;
}
.grid-column-2-4-desktop {
  grid-column: 2 / 4;
}
.grid-row-start-span-2-desktop {
  grid-row-start: span 2;
}
.column-template-1-1fr {
  grid-template-columns: 1fr 1fr;
}
.column-template-2-1fr {
  grid-template-columns: 2fr 1fr;
}
.column-template-1-4-1fr {
  grid-template-columns: 1fr 4fr 1fr;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3-desktop {
  grid-column-start: 3;
}
.row-gap-15px {
  row-gap: 15px;
}
.row-gap-35px {
  row-gap: 35px;
}
.row-start-2-desktop {
  grid-row-start: 2;
}
.border-radius-5px {
  border-radius: 5px;
}

.border-radius-bottom-5px {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.border-style-solid {
  border-style: solid;
}

.border-width-3px {
  border-width: 3px;
}

.border-color-darkgrey {
  border-color: #d8d9dd;
}

.border-color-light {
  border-color: rgb(228, 225, 225);
}

.cursor-pointer {
  cursor: pointer;
}

.margin-top-0px {
  margin-top: 0px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-15px {
  margin-top: 15px;
}

.padding-top-40px {
  padding-top: 40px;
}

.dollar {
  display: inline-block;
  position: relative;
}
.dollar input {
  padding-left: 15px;
}
.dollar:before {
  position: absolute;
  content: '$';
  left: 5px;
  top: 9px;
}

.margin-all-0px {
  margin: 0px;
}

.margin-bottom-0px {
  margin-bottom: 0px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-55px {
  margin-top: 55px;
}

.margin-bottom-65px {
  margin-bottom: 65px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-right-15px {
  margin-right: 15px;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-25px {
  margin-left: 25px
}

.margin-left-50px {
  margin-left: 50px
}

.margin-all-25px {
  margin: 25px;
}

.chart-key-sizing {
  background: 'white';
  width: 26px;
  height: 26px;
  border: solid 7px;
  border-radius: 50%;
}

.custom-orange-btn {
  background-color: $color-orange !important; 
  color: $color-white !important;
}

.custom-orange-btn:hover {
  background-color: $color-orange-dark !important; 
}

.custom-white-btn {
  background-color: $color-white !important; 
  color: $color-blue-dark !important;
}

.custom-white-btn:hover {
  background-color: $color-white-dark !important; 
  color: $color-blue-dark !important;
}

// global animation: ease upwards
.ease-up{
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
  z-index: 1;
}

.ease-up.active{
  transform: translateY(0);
  opacity: 1;
}

.ease-up-immediate {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
  z-index: 1;
}

.ease-up-immediate.active {
  transform: translateY(0);
  opacity: 1;
}


.ease-up-quick {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.5s all ease;
  z-index: 1;
}

.ease-up-quick.active{
  transform: translateY(0);
  opacity: 1;
}

.layout-padding {
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.monaco-editor .inputarea {
  position: static !important;
}

@media screen and (max-width: 767px) {
  /* start of medium tablet styles */
  .pad-bottom-200px-desktop {
    padding-bottom: 0;
  }

  .flex {
    flex-direction: column;
  }

  .max-with-20-percent-desktop {
    max-width: inherit;
  }

  .max-with-30-percent-desktop {
    max-width: inherit;
  }

  .grid-column-2-4-desktop {
    grid-column: auto;
  }
  .column-template-2-1fr {
    grid-template-columns: 1fr;
  }
  .col-start-3-desktop {
    grid-column-start: auto;
  }
  .row-start-2-desktop {
    grid-row-start: auto;
  }
  .pad-top-30px-desktop {
    padding-top: 0px;
  }
  .pad-top-128px-desktop {
    padding-top: 0px;
  }

  .flex-row-mobile {
    flex-direction: row;
  }
  .column-template-1-1fr {
    grid-template-columns: 1fr;
  }

  .max-with-20-percent-desktop {
    max-width: 100%;
  }

  .max-with-30-percent-desktop {
    max-width: 100%;
  }

  .max-with-70-percent-desktop {
    max-width: 100%;
  }

  .layout-padding {
    padding-left: 1%;
    padding-right: 1%;
  }
}

/* Start of big tablet */
@media screen and (min-width: 768px) {
  .background-grey-80percent {
    background: linear-gradient(#f0f1f6 80%, transparent 40%) no-repeat;
  }

  .max-with-20-percent-desktop {
    max-width: 20%;
  }

  .max-with-30-percent-desktop {
    max-width: 30%;
  }

  .max-with-70-percent-desktop {
    max-width: 70%;
  }
}

.MuiTableRow-root:not([style]) {
  background-color: #f0f1f6;
  font-size: 1.07rem;
  font-weight: bold;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiTableSortLabel-icon {
  opacity: 0 !important;
}

.MuiTableCell-head:hover {
  .MuiTableSortLabel-icon {
    opacity: 1 !important;
  }
}

.MuiTableSortLabel-active {
  .MuiTableSortLabel-icon {
    opacity: 1 !important;
  }
}

.MuiTableRow-root:not([style]) {
  background-color: white !important;
}

.MuiTablePagination-toolbar {
  button.MuiIconButton-root {
    background-color: #f0f1f6 !important;
    padding: 12px 9.5px;
  }

  button.MuiIconButton-root:hover {
    background-color: #0156d0 !important;
    .icon {
      color: white;
    }
  }
}
.MuiTablePagination-toolbar > div > span {
  padding-left: 6px;
  padding-right: 6px;
}

.pad-disclaimer-checkbox {
  padding: 20px 0px;
}

.hr {
  background-color: #0156d0;
}

.inline-block {
  display: inline-block;
}


@media only screen and (max-width: 768px) {
  .truncate-table-text {
    white-space: wrap;
  }
}